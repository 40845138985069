<template>
    <div class="VVVLayoutNew">
        <div v-if="isMobile && isExpanded" class="overlay" @click="toggleSideBar"></div>
        <MDBNavbar id="mainNavbar" class="p-2 px-4 sticky-top" style="padding-left: 11.6px; z-index: 100;">
            <MDBBtn :class="isMobile ? 'd-block ms-1' : 'd-none'" color="tertiary" aria-controls="#mainSidenav"
                @click="toggleSideBar" id="mainSidenavTogglerNavbar" style="padding-left: 0;">
                <vue-feather type="menu" :stroke-width="2.5" style="height: 1rem;margin-top: 4px" />
            </MDBBtn>
            <h5
                :class="isExpanded ? 'page-title-expanded my-auto text-black d-none d-md-block' : 'page-title-slim my-auto text-black d-none d-md-block'">
                <slot name="page-title"></slot>
            </h5>
            <MDBNavbarNav right>
                <MDBNavbarItem>
                    <MDBDropdown btnGroup align="lg-end" v-model="userDropdown" id="userDropdown"
                        class="gap-md-4 gap-3 d-flex flex-column flex-md-row">
                        <p v-if="(isAdmin || isSuperAdmin) && !isMobile" class="m-auto text-dark current-date">
                            <span class="fw-bold me-2">{{ currentTime }}</span>{{ currentDate }}
                        </p>
                        <!-- Temporary hidden -->
                        <!-- <template v-if="isUser && userDetail.assetLabelName">
                            <MDBTooltip v-model="assetLabelTooltip"
                                class="d-flex align-items-center justify-content-end" placement="bottom">
                                <template #reference>
                                    <MDBBtn
                                        class="rounded-pill asset-label-button-hover d-flex align-items-center gap-1"
                                        size="sm" style="font-size: 11.5px;max-width: 200px;"
                                        @click="toggleAssetLabelModal()">
                                        <MDBIcon icon="lock" class="fas fa-fw me-1" />
                                        <span class="text-truncate asset-label">ID: {{ userDetail.assetLabelName
                                            }}</span>
                                    </MDBBtn>
                                </template>
<template #tip>
                                    Asset Label ID
                                </template>
</MDBTooltip>
</template> -->
                        <MDBDropdownToggle tag="a" class="nav-link d-flex align-items-center justify-content-end"
                            @click="userDropdown = !userDropdown" color="dark">
                            <div class="default-avatar shadow-lg me-1" v-if="!userDetail.profilePictureUrl">
                                <span class="avatar-letter">{{ avatarLetter }}</span>
                            </div>
                            <img v-else :src="avatarImage" alt="Profile Picture"
                                class="profile-avatar shadow-lg me-1" />
                            <div class="d-flex flex-column align-items-center mx-2 justify-content-start">
                                <div class="text-capitalize">
                                    {{ userDetail.firstName }}
                                    {{ userDetail.lastName }}
                                </div>
                                <span class="w-100 user-type"
                                    style="text-transform: uppercase; font-size: 11px; font-weight: bold;"
                                    :style="{ color: setUserTypeColor(userDetail.userTypeId) }">
                                    {{ setUserType(userDetail.userTypeId) }}
                                </span>
                            </div>
                        </MDBDropdownToggle>
                        <MDBDropdownMenu>
                            <MDBDropdownItem class="nav-link-item d-flex align-items-center" href="#/user-profile">
                                <vue-feather type="user" :stroke-width="2.5" size="18px" class="me-2" />
                                <span>User Profile</span>
                            </MDBDropdownItem>
                            <MDBDropdownItem class="nav-link-item d-flex align-items-center" href="#"
                                @click="handleLogout">
                                <vue-feather type="log-out" :stroke-width="2.5" size="18px" class="me-2" />
                                <span>Logout</span>
                            </MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown>
                </MDBNavbarItem>
            </MDBNavbarNav>
        </MDBNavbar>
        <div class="layout-wrapper">
            <div :class="['sidenav', 'd-flex', 'flex-column', isSlim ? 'slim' : '', isExpanded ? 'expanded' : '']"
                id="sidenav">
                <div class="d-flex align-items-center sticky-top bg-white">
                    <MDBBtn :class="isMobile ? 'd-block ms-4' : 'd-none'" color="tertiary" aria-controls="#mainSidenav"
                        @click="toggleSideBar" id="mainSidenavTogglerNavbar" style="padding-left: 0;">
                        <vue-feather type="menu" :stroke-width="2.5" style="height: 1rem;margin-top: 4px" />
                    </MDBBtn>
                    <div v-if="isExpanded"
                        :class="isMobile ? 'd-flex justify-content-start py-2' : 'd-flex justify-content-center expanded-logo-container'">
                        <img @click="toDashboard" :src="expandedLogo" alt="creator-shield-logo"
                            class="img-fluid object-fit-contain sidenav-non-slim sidenav-mobile-logo" />
                    </div>
                    <div v-else class="d-flex justify-content-center" style="padding: 11px 10px">
                        <img @click="toDashboard" :src="slimLogo" alt="creator-shield-logo"
                            class="img-fluid object-fit-contain" style="width: 67%;" />
                    </div>
                </div>
                <hr class="m-0" />
                <router-link to="/dashboard" active-class="bg-primary text-white" class="d-flex align-items-center">
                    <vue-feather type="grid" :stroke-width="2.5" class="sidenav-feather-icon" />
                    <span v-if="isExpanded" class="navlink-text">Dashboard</span>
                </router-link>
                <router-link to="/earnings" active-class="bg-primary text-white" class="d-flex align-items-center">
                    <vue-feather type="bar-chart-2" :stroke-width="2.5" class="sidenav-feather-icon" />
                    <span v-if="isExpanded" class="navlink-text">Earnings</span>
                </router-link>
                <router-link to="/claims-list" active-class="bg-primary text-white" class="d-flex align-items-center">
                    <vue-feather type="play-circle" :stroke-width="2.5" class="sidenav-feather-icon" />
                    <span v-if="isExpanded" class="navlink-text">{{ isUser ? 'My Claimed Videos' : 'Claims List'
                        }}</span>
                </router-link>
                <router-link v-if="isUser" to="/new-claim" active-class="bg-primary text-white"
                    class="d-flex align-items-center">
                    <vue-feather type="plus-circle" :stroke-width="2.5" class="sidenav-feather-icon" />
                    <span v-if="isExpanded" class="navlink-text">New Claim</span>
                </router-link>
                <router-link v-if="isUser" to="/bulk-claims" active-class="bg-primary text-white"
                    class="d-flex align-items-center">
                    <vue-feather type="folder-plus" :stroke-width="2.5" class="sidenav-feather-icon" />
                    <span v-if="isExpanded" class="navlink-text">Bulk Claims</span>
                </router-link>
                <router-link v-if="isAdmin || isSuperAdmin" to="/users" active-class="bg-primary text-white"
                    class="d-flex align-items-center">
                    <vue-feather type="users" :stroke-width="2.5" class="sidenav-feather-icon" />
                    <span v-if="isExpanded" class="navlink-text">Users</span>
                </router-link>
                <div v-if="isUser" :class="['d-flex align-items-center category', { 'expanded': isExpanded }]"
                    @click="toggleCategory('dailySyncSubMenu')">
                    <vue-feather type="video" :stroke-width="2.5" class="sidenav-feather-icon" />
                    <span v-if="isExpanded" class="navlink-text">Our Video Library</span>
                    <vue-feather v-if="isExpanded && !isDailySyncSubMenuVisible" type="chevron-down"
                        style="height:1.1rem;margin-left: auto;" />
                    <vue-feather v-if="isExpanded && isDailySyncSubMenuVisible" type="chevron-up"
                        style="height:1.1rem;margin-left: auto;" />
                </div>
                <div v-if="isExpanded && isDailySyncSubMenuVisible" class="sub-menu">
                    <a href="https://www.dropbox.com/scl/fo/z9yrfj44fzys9lh96owna/AAiiSr1W5usyp-1XXu-kxvA?rlkey=fe9ob760xpxr08qc64jxfbhs5&e=1&st=g2uwgrtj&dl=0"
                        target="_blank" title="Staff Picks" active-class="bg-primary text-white"
                        class="d-flex align-items-center py-2">
                        <vue-feather type="star" :stroke-width="2.5" class="sidenav-feather-icon" />
                        <span v-if="isExpanded" class="navlink-text">Staff Picks</span>
                    </a>
                    <a href="https://www.dropbox.com/scl/fo/z9yrfj44fzys9lh96owna/AKpsRdfT8IVnK5the3BfTLs/Top%20Trending%20this%20Month!!!?dl=0&rlkey=fe9ob760xpxr08qc64jxfbhs5&subfolder_nav_tracking=1"
                        target="_blank" title="Top Trending" active-class="bg-primary text-white"
                        class="d-flex align-items-center py-2">
                        <vue-feather type="trending-up" :stroke-width="2.5" class="sidenav-feather-icon" />
                        <span v-if="isExpanded" class="navlink-text">Top Trending</span>
                    </a>
                    <a href="https://www.dropbox.com/scl/fo/n7jxkij7s468wuorhdddn/AF4y1-GOx9eKYKCPmmFcpbA?rlkey=29khtepf48f4ru5todtypdkyc&e=1&st=4bmt8j52&dl=0"
                        target="_blank" title="Top Comedy" active-class="bg-primary text-white"
                        class="d-flex align-items-center py-2">
                        <vue-feather type="trending-up" :stroke-width="2.5" class="sidenav-feather-icon" />
                        <span v-if="isExpanded" class="navlink-text">Top Comedy</span>
                    </a>
                    <a href="https://www.dropbox.com/scl/fo/z9yrfj44fzys9lh96owna/AHmCDaTwDQ0A_rqxHPOfYDY/Top%20Pets?dl=0&rlkey=fe9ob760xpxr08qc64jxfbhs5&subfolder_nav_tracking=1"
                        target="_blank" title="Pets" active-class="bg-primary text-white"
                        class="d-flex align-items-center py-2">
                        <span class="ms-1">🔥</span>
                        <span v-if="isExpanded" class="navlink-text">Pets</span>
                    </a>
                    <a href="https://www.dropbox.com/scl/fo/z9yrfj44fzys9lh96owna/AN_0FrRGKHffIvHUK7QqY80/Top%20Dancing?dl=0&rlkey=fe9ob760xpxr08qc64jxfbhs5&subfolder_nav_tracking=1"
                        target="_blank" title="Dancing" active-class="bg-primary text-white"
                        class="d-flex align-items-center py-2">
                        <span class="ms-1">🔥</span>
                        <span v-if="isExpanded" class="navlink-text">Dancing</span>
                    </a>
                    <a href="https://www.dropbox.com/scl/fo/z9yrfj44fzys9lh96owna/AAyrC0-IyKsesusWokUW31k/Top%20Funny%20Fails?dl=0&rlkey=fe9ob760xpxr08qc64jxfbhs5&subfolder_nav_tracking=1"
                        target="_blank" title="Funny Fails" active-class="bg-primary text-white"
                        class="d-flex align-items-center py-2">
                        <span class="ms-1">🔥</span>
                        <span v-if="isExpanded" class="navlink-text">Funny Fails</span>
                    </a>
                    <a href="https://www.dropbox.com/scl/fo/z9yrfj44fzys9lh96owna/AOt9l6tkqoItG_C5JLqHXGE/Top%20DIY%20and%20Orgnization?dl=0&rlkey=fe9ob760xpxr08qc64jxfbhs5&subfolder_nav_tracking=1"
                        target="_blank" title="D.I.Y. / Organization" active-class="bg-primary text-white"
                        class="d-flex align-items-center py-2">
                        <span class="ms-1">🔥</span>
                        <span v-if="isExpanded" class="navlink-text">D.I.Y.</span>
                    </a>
                    <a href="https://www.dropbox.com/scl/fo/j2543l4jion4xrcq9llh9/h?rlkey=25oqfir3tefqca7138qibwyzv&e=1&dl=0"
                        target="_blank" title="View All" active-class="bg-primary text-white"
                        class="d-flex align-items-center py-2">
                        <vue-feather type="arrow-up-right" :stroke-width="2.5" class="sidenav-feather-icon" />
                        <span v-if="isExpanded" class="navlink-text">View All</span>
                    </a>
                </div>
                <router-link to="/tutorials" active-class="bg-primary text-white" class="d-flex align-items-center">
                    <vue-feather type="book" :stroke-width="2.5" class="sidenav-feather-icon" />
                    <span v-if="isExpanded" class="navlink-text">Tutorials</span>
                </router-link>
                <div class="mt-auto" style="border-top: 1px solid #dfdfdf;">
                    <div :class="{ 'mt-auto': userDetail.userTypeId === 2 }">
                        <div :class="isExpanded ? 'd-flex justify-content-center align-items-center' : 'd-block'">
                            <button v-if="isExpanded" class="category d-flex justify-content-center align-items-center"
                                @click="toggleSideBar">
                                <vue-feather type="chevrons-left"
                                    style="height:1.1rem;margin: 2.4px 0;margin-right: -2px;" />
                            </button>
                            <button v-else class="category d-flex justify-content-center align-items-center"
                                @click="toggleSideBar">
                                <vue-feather type="chevrons-right" style="height:1.1rem;margin-left: -2px;" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div :id="isMobile ? 'content-mobile' : (isExpanded ? 'content-expanded' : 'content-slim')">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script setup>
import { MDBNavbar, MDBBtn, MDBNavbarNav, MDBNavbarItem, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem/* , MDBTooltip, MDBIcon, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModal */ } from "mdb-vue-ui-kit";
import { computed, onMounted, ref, watch, watchEffect, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
import { useUserDetailStore } from "@/store/userDetailStore";
import { GetCurrent } from "@/services/Users/GetCurrent";
import { Logout } from "@/services/Logout/Logout";
import { storeToRefs } from "pinia";
import VueFeather from 'vue-feather';
import { showCurrentDateTimeWithSeconds } from "@/helpers/parseDate";
import Intercom from '@intercom/messenger-js-sdk';

const getSidebarStateFromLocalStorage = () => {
    const storedState = localStorage.getItem('sidebarState');
    if (storedState) {
        return JSON.parse(storedState);
    }
    return { isSlim: true, isExpanded: false };
};

const storedSidebarState = getSidebarStateFromLocalStorage();

const isSlim = ref(storedSidebarState.isSlim);
const isExpanded = ref(storedSidebarState.isExpanded);

const saveSidebarState = () => {
    const sidebarState = {
        isSlim: isSlim.value,
        isExpanded: isExpanded.value,
    };
    localStorage.setItem('sidebarState', JSON.stringify(sidebarState));
};

watch([isSlim, isExpanded], saveSidebarState);

onMounted(() => {
    updateResolution();
    window.addEventListener('resize', updateResolution);

    if (!isMobile.value) {
        const storedSidebarState = localStorage.getItem('sidebarState');
        if (storedSidebarState) {
            const parsedState = JSON.parse(storedSidebarState);
            isSlim.value = parsedState.isSlim !== undefined ? parsedState.isSlim : true;
            isExpanded.value = parsedState.isExpanded !== undefined ? parsedState.isExpanded : false;
        }
    } else {
        isSlim.value = true;
        isExpanded.value = false;
    }
});

const toggleSideBar = () => {
    isExpanded.value = !isExpanded.value;
    isSlim.value = !isExpanded.value;
};

watch([isSlim, isExpanded], () => {
    if (!isMobile.value) {
        const sidebarState = {
            isSlim: isSlim.value,
            isExpanded: isExpanded.value,
        };
        localStorage.setItem('sidebarState', JSON.stringify(sidebarState));
    }
});

watch(isExpanded, (newVal) => {
    if (newVal) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = '';
    }
});

const isMobile = ref(window.innerWidth <= 767);


// const assetLabelTooltip = ref();
// const showAssetLabeltModal = ref(false);
// const toggleAssetLabelModal = () => {
//     showAssetLabeltModal.value = true;
// };

const updateResolution = () => {
    isMobile.value = window.innerWidth <= 767;
};

watchEffect(() => {
    if (isMobile.value) {
        isExpanded.value = false;
    }
});

onMounted(() => {
    window.addEventListener('resize', updateResolution);
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', updateResolution);
});

const router = useRouter();
const toDashboard = () => {
    router.push("/dashboard");
};

const adjustmentsURL = ref("");
const userDropdown = ref(false);

const userDetailStore = useUserDetailStore();
const { userDetail, isUser, isAdmin, isSuperAdmin } = storeToRefs(userDetailStore);
onMounted(async () => {
    const response = await GetCurrent();
    userDetail.value = response;
    setLiveChat(response);
    adjustmentsURL.value = `/users/${response.id}/adjustments`;
    setCurrentDate()
    setInterval(setCurrentDate, 1000);
});

const currentTime = ref("");
const currentDate = ref("");

const setCurrentDate = () => {
    const { time, date } = showCurrentDateTimeWithSeconds();
    currentTime.value = time;
    currentDate.value = date;
};

const handleLogout = () => {
    Logout().then(() => {
        router.push("/login");
    });
    window.dispatchEvent(new Event('reset-alerts'));
};

const setUserType = (userTypeId) => {
    switch (userTypeId) {
        case 1:
            return "Admin";
        case 2:
            return "User";
        case 3:
            return "Distributor";
        case 4:
            return "Super Admin";
        default:
            return "-";
    }
}

const setLiveChat = (user) => {
    Intercom({
        app_id: 'ts0mhyws',
        user_id: user.id,
        name: user.name,
        email: user.email,
        created_at: Math.floor(Date.now() / 1000),
    });
}

const setUserTypeColor = (userTypeId) => {
    switch (userTypeId) {
        case 1:
            return "var(--blue)";
        case 4:
            return "var(--primary)";
        case 2:
        case 3:
        default:
            return "var(--accent)";
    }
}

const avatarLetter = computed(() => {
    return userDetail.value?.firstName?.charAt(0).toUpperCase() || '';
});

const expandedLogo = ref(require('@/assets/VVV/vvv-black-with-text.png'));
const slimLogo = ref(require('@/assets/VVV/vvv-raw-black.png'));

const avatarImage = ref(userDetail.value.profilePictureUrl);
watch(() => userDetail.value.profilePictureUrl, (newUrl, oldUrl) => {
    if (newUrl !== oldUrl) {
        avatarImage.value = newUrl;
    }
});

router.beforeEach((to, from, next) => {
    const storedSidebarState = localStorage.getItem('sidebarState');
    if (storedSidebarState) {
        const parsedState = JSON.parse(storedSidebarState);
        isSlim.value = parsedState.isSlim;
        isExpanded.value = parsedState.isExpanded;
    }
    next();
});

const isDailySyncSubMenuVisible = ref(false);
const toggleCategory = (category) => {
    if (category === 'dailySyncSubMenu') {
        if (!isExpanded.value) {
            isExpanded.value = true;
        }
        isDailySyncSubMenuVisible.value = !isDailySyncSubMenuVisible.value;
    }
};
</script>

<style>
:root {
    --white: #ffffff;
    --primary: #223698;
    --secondary: #e2e6ef;
    --accent: #02061b;
    --blue: #1f87fc;
}

@font-face {
    font-family: 'GilroyRegular';
    src: url('../../assets/Gilroy-Font/Gilroy-Regular.ttf');
}

@font-face {
    font-family: 'GilroyBold';
    src: url('../../assets/Gilroy-Font/Gilroy-Bold.ttf');
}

* {
    font-family: 'GilroyRegular', sans-serif;
}

.page-title-slim,
.page-title-expanded,
th,
.avatar-letter,
.user-type,
.current-date,
.asset-label {
    font-family: 'GilroyBold';
}

.sidenav-non-slim,
.sidebarDropdownTitle {
    font-weight: bolder;
}

.sidenav-link:hover,
.sidenav-link:focus,
.sidenav-link.active {
    background-color: var(--primary) !important;
    color: white !important;
}

.tooltip-inner {
    background-color: var(--primary);
    font-size: 12px;
    padding: 3px 12px;
}

.btn-primary {
    background-color: var(--primary);
}
</style>

<style scoped>
.bg-primary {
    background-color: var(--primary) !important;
}

.sidenav {
    height: 100%;
    width: 70px;
    position: fixed;
    left: 0;
    top: 0;
    background-color: white;
    transition: width 0.3s ease;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index: 1050;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1050;
}

.sidenav.expanded {
    width: 250px;
}

.sidenav a {
    padding: 15px 20px;
    text-decoration: none;
    color: black;
    display: block;
    transition: all 0.3s;
}

.sidenav .category {
    cursor: pointer;
    background-color: white;
    padding: 15px 20px;
    display: block;
    color: black;
    border: none;
    width: 100%;
    text-align: left;
    outline: none;
}

.sidenav .sub-menu {
    padding-left: 25px;
}

.sidenav a:hover,
.sidenav .category:hover {
    background-color: var(--primary);
    color: white;
}

@media (max-width: 767px) {
    .sidenav.slim {
        display: none !important;
    }

    .sidenav.expanded {
        display: flex !important;
    }
}

.VVVLayoutNew {
    background-color: var(--white) !important;
    overflow-y: auto;
}

#mainNavbar {
    background-color: white;
    color: var(--primary);
}

#mainSidenavTogglerNavbar,
#mainSidenavTogglerSidenav {
    font-size: 16px;
    box-shadow: none;
}

:global(#mainSidenav + .sidenav-backdrop) {
    background-color: rgba(0, 0, 0, 0.4);
}

:global(#mainSidenav .sidenav-menu) {
    color: var(--accent);
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
}

.logo2 {
    width: 40px;
}

.nav-link {
    color: var(--accent);
    padding: 0;
}

:deep(#mainSidenav .nav-link-item:hover) {
    background-color: var(--primary);
    color: white;
    cursor: pointer;
}

.sidenav-link {
    border-radius: 0;
}

.sidenav-link.active {
    background-color: var(--primary);
    color: white;
    cursor: pointer;
}

#userDropdown {
    box-shadow: none;
}

#content-slim {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 90px;
}

#content-expanded {
    margin-left: 250px;
    transition: margin-left 0.3s;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);
    padding-top: 20px;
    padding-inline: 20px;
}

#content-mobile {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);
    padding-top: 20px;
    padding-inline: 20px;
}

.impersonate-banner {
    display: flex;
    align-items: center;
    color: white;
    background-color: var(--primary);
}

.impersonate-banner p {
    margin-bottom: 0;
    margin-right: auto;
}

.impersonate-banner :deep(span) {
    cursor: pointer;
}

.page-title-slim {
    padding-left: 68px;
}

.page-title-expanded {
    padding-left: 250px;
}

.feather-icon {
    height: 1rem;
}

.sidenav-feather-icon {
    height: 1rem;
    margin-left: 5.8px;
}

.sidenav-item-collapse {
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 15px 20px;
    display: block;
    border: none;
    width: 100%;
    text-align: left;
    outline: none;
    color: black;
}

.sidenav-item {
    position: relative;
    background-color: rgba(0, 0, 0, 0.3);
}

.sidenav-overlay-icon {
    height: 1.4rem;
    position: absolute;
    top: 26%;
    left: 49%;
    transform: translateX(-50%);
    z-index: 1;
}

.sub-sidenav-feather-icon {
    height: 1rem;
    margin-left: -5px;
}

.navlink-text {
    margin-left: 12px;
    font-weight: bold;
    font-size: 14px;
}

.default-avatar {
    width: 40px;
    height: 40px;
    background-color: var(--primary);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 20px;
    font-weight: bold;
}

.profile-avatar {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    object-fit: cover;
}

.avatar-letter {
    text-transform: uppercase;
}

.sidenav-mobile-logo {
    width: 76%;
}

.expanded-logo-container {
    padding: 5px;
}

.current-date {
    font-size: 15px
}

@media screen and (max-width: 767px) {
    .sidenav-mobile-logo {
        width: 81%;
        margin-left: 12px
    }

    #content {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 60px);
        padding-top: 20px;
        padding-inline: 20px;
    }

    .current-date {
        font-size: 14px
    }
}

.active-page {
    background-color: var(--primary) !important;
    color: white !important;
}

.asset-label-button-hover,
.asset-label-button-hover:hover {
    background-color: var(--primary);
    color: white;
}

button.btn-close {
    color: white !important;
    filter: brightness(0) invert(1) !important;
}

#sidenav::-webkit-scrollbar {
    width: 8px;
    height: 5px;
}

#sidenav::-webkit-scrollbar-thumb {
    background-color: #888;
}

#sidenav::-webkit-scrollbar-track {
    background-color: #eee;
}
</style>
